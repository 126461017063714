import request from '@/utils/request';

export function getCitys(data) {
  return request({
    url: 'misc-web-api/common/district/queryById',
    method: 'post',
    data,
  });
} //获取城市列表
export function getCityTrees(data) {
  return request({
    url: 'misc-web-api/common/district/tree',
    method: 'post',
    data,
  });
} //获取城市树

export function getStoreQuery(data) {
  return request({
    url: 'supplier-web-api/admin/store/getList',
    method: 'post',
    data,
  });
} //获取门店分页列表
export function getStoreDetail(data) {
  return request({
    url: 'supplier-web-api/admin/store/detail',
    method: 'post',
    data,
  });
} //获取门店详情
export function addStore(data) {
  return request({
    url: 'supplier-web-api/admin/store/save',
    method: 'post',
    data,
  });
} //添加门店
export function updateStore(data) {
  return request({
    url: 'supplier-web-api/admin/store/edit',
    method: 'post',
    data,
  });
} //门店设置（编辑）
export function verPhone(data) {
  return request({
    url: 'supplier-web-api/admin/user/info/platform/checkPhoneNumber',
    method: 'post',
    data,
  });
} //门店设置（编辑）
export function resetPassword(data) {
  return request({
    url: 'supplier-web-api/admin/store/resetPassword',
    method: 'post',
    data,
  });
} //重置密码

export function getStoreProductList(data) {
  return request({
    url: 'supplier-web-api/admin/store/listProductByStoreId',
    method: 'post',
    data,
  });
} //查询门店所属服务产品列表
export function getProductDetail(data) {
  return request({
    url: 'product-web-api/admin/product/findSupplierProductDetail',
    method: 'post',
    data,
  });
} //查询服务产品列表
export function getProductList(data) {
  return request({
    url: 'supplier-web-api/admin/store/listServiceProduct',
    method: 'post',
    data,
  });
} //查询服务产品详情
export function addServerProdcut(data) {
  return request({
    url: 'supplier-web-api/admin/store/saveStoreProduct',
    method: 'post',
    data,
  });
} //添加服务产品
export function deleteServerProdcut(data) {
  return request({
    url: 'supplier-web-api/admin/store/delServiceProduct',
    method: 'post',
    data,
  });
} //删除服务产品
export function storeApply(data) {
  return request({
    url: 'supplier-web-api/admin/store/storeApply',
    method: 'post',
    data,
  });
} //入网申请

export function storeBank(data) {
  return request({
    url: 'supplier-web-api/admin/store/storeBank',
    method: 'post',
    data,
  });
} //提现设置

export function getStoreApplyInfo(data) {
  return request({
    url: 'supplier-web-api/admin/store/getStoreApplyInfo',
    method: 'post',
    data,
  });
} //查询申请信息

export function getStoreBankInfo(data) {
  return request({
    url: 'supplier-web-api/admin/store/getStoreBankInfo',
    method: 'post',
    data,
  });
} //查询提现银行卡

export function getListExport(data) {
  return request({
    url: '/supplier-web-api/admin/store/getListExport',
    method: 'post',
    data,
    responseType: 'blob',
  });
}
export function storeproportion(data) {
  return request({
    url: '/supplier-web-api/admin/storeproportion/get',
    method: 'post',
    data,
  });
}
export function storeproportionSave(data) {
  return request({
    url: '/supplier-web-api/admin/storeproportion/save',
    method: 'post',
    data,
  });
}
export function getStoreBySampleList(data) {
  return request({
    url: '/supplier-web-api/admin/store/sample/list',
    method: 'post',
    data,
  });
}
export function saveSample(data) {
  return request({
    url: '/supplier-web-api/admin/store/sample/save',
    method: 'post',
    data,
  });
}
export function deleteSampleByIds(data) {
  return request({
    url: '/supplier-web-api/admin/store/sample/deleteByIds',
    method: 'post',
    data,
  });
}
export function findSampleStatisticNumByStoreId(data) {
  return request({
    url: '/supplier-web-api/admin/store/sample/findSampleStatisticNumByStoreId',
    method: 'post',
    data,
  });
}

export function updateOnShelf(data) {
  return request({
    url: '/supplier-web-api/admin/store/sample/updateOnShelf',
    method: 'post',
    data,
  });
}

export function getSaleUserTree(data) {
  return request({
    url: 'chance-web-api/admin/sale/getSaleUserTree',
    method: 'post',
    data,
  });
}

export function addFirmSale(data) {
  return request({
    url: 'supplier-web-api/admin/firm/addFirmSale',
    method: 'post',
    data,
  });
}

export function getFirmSaleList(data) {
  return request({
    url: 'supplier-web-api/admin/firm/getFirmSaleList',
    method: 'post',
    data,
  });
}
